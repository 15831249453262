<template>
  <b-modal
    id="warehouse-entry-file-modal"
    title="Arquivos anexados"
    hide-header
    hide-footer
    centered
    size="lg"
  >
    <div class="modal-header d-flex flex-row justify-content-between">
      <p class="modal-title">
        Arquivos anexados
      </p>
      <div>
        <b-button
          size="lg"
          variant="outline"
          class="attach-button"
          @click="editFile"
        >
          <Clip class="clip" />
          Anexar arquivo
        </b-button>
        <Close class="icon" @click="closeModal" />
      </div>
    </div>
    <div class="modal-body" style="padding: 24px;">
      <WarehouseEntryInfo :visible="true" class="mb-4" />
      <div class="divider"></div>
      <b-row v-if="file">
        <b-col cols="9">
          <b-row>
            <b-col cols="12">
              <p class="name">
                {{ file?.filename }}
              </p>
            </b-col>
            <b-col>
              <p class="info">
                {{ moment(file?.created_at).format('DD [de] MMMM [de] YYYY') }}
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3" class="text-right">
          <DownloadFile class="icon download mr-2" @click="downloadFile" />
          <Edit class="icon edit mr-2" @click="editFile" />
          <Delete class="icon delete" @click="removeFile" />
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <p class="name">
            Não há arquivos anexados.
          </p>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>

export default {
  name: 'WarehouseEntryFileModal',
  components: {
    WarehouseEntryInfo: () => import('@/components/Warehouse/WarehouseEntryInfo.vue'),
    DownloadFile: () => import('@/assets/icons/download-file.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Delete: () => import('@/assets/icons/delete.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    Clip: () => import('@/assets/icons/clip.svg')
  },
  props: {
    file: Object,
    entry: Object
  },
  methods: {
    async downloadFile() {
      const loading = this.$loading.show()
      try {
        const { data } = await this.api.getDocTemporaryUrl(this.file.path)
        window.open(data, '_blank')
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    editFile() {
      this.$bvModal.show('generic-attach-file-modal')
      this.$bvModal.hide('warehouse-entry-file-modal')
    },
    removeFile() {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente apagar o arquivo da entrada?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        const loading = this.$loading.show()
        if (res.isConfirmed) {
          this.api.deleteWarehouseEntryFile(this.file.id).then(
            () => {
              this.$toast.success('Arquivo excluído com sucesso!')
            }
          ).catch(
            (err) => {
              this.$toast.error(err.message)
            }
          ).finally(
            () => {
              loading.hide()
              this.$emit('reload-entries')
              this.closeModal()
            }
          )
        }
      })
    },
    closeModal() {
      this.$emit('close-modal')
      this.$bvModal.hide('warehouse-entry-file-modal')
    }
  }
}
</script>
<style scoped lang="scss">
.icon {
  width: 24px;
  cursor: pointer;
}

.divider {
  border-bottom: 1px solid var(--neutral-300);
  margin: 16px 0;
}

.info {
  font-weight: 600;
  font-size: 12px;
  color: var(--type-placeholder);
  white-space: nowrap;
}

.name {
  font-weight: 600;
  font-size: 16px;
  color: var(--type-active);
}

.attach-button {
  color: var(--blue-500);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  .clip {
    margin-top: -5px;
    stroke: var(--blue-500);
  }
}
</style>